.contact-section {
  background-color: #333;
  color: #fff;
  padding: 60px 20px;
  text-align: center;
}

.contact-section h2 {
  font-size: 2em;
  margin-bottom: 40px;
  font-weight: bold;
}

.contact-section .cta-button-contact {
  background: red;
  color: white;
  border: none;
  padding: 15px 40px; /* Adjusted padding for smaller screens */
  font-size: 1.4em; /* Adjusted font size for smaller screens */
  border-radius: 30px; /* Adjusted border radius for smaller screens */
  cursor: pointer;
  margin: 20px 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.contact-section .cta-button-contact:hover {
  background: darkred;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
