.contact-form {
  background-color: #333;
  color: #fff;
  padding: 60px 20px;
  text-align: center;
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

.contact-form h2 {
  font-size: 2em;
  margin-bottom: 20px;
  font-weight: bold;
}

.contact-form p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
}

.contact-form .form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.contact-form .form-group1,
.contact-form .form-group2 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%; /* Ensure it takes the full width */
}

.contact-form .form-group1 input,
.contact-form .form-group2 input,
.contact-form .form-group2 textarea {
  flex: 1 1 calc(50% - 20px);
  box-sizing: border-box; /* Include padding in the width calculation */
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #444;
  color: #ffffff;
  margin-bottom: 20px;
  box-sizing: border-box; /* Include padding in the width calculation */
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #ccc;
  opacity: 1;
  font-family: Arial, sans-serif;
}

.contact-form textarea {
  height: 100px;
  resize: none;
}

.contact-form .send-button-form {
  background: red;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2em;
  border-radius: 55px;
  cursor: pointer;
  margin-top: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.contact-form .send-button-form:hover {
  background: darkred;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

/* Media Queries for Responsive Adjustments */
@media (max-width: 768px) {
  .contact-form h2 {
    font-size: 1.5em;
  }

  .contact-form p {
    font-size: 1em;
  }

  .contact-form .form-group1,
  .contact-form .form-group2 {
    flex-direction: column;
    gap: 10px; /* Reduce gap for smaller screens */
  }

  .contact-form .form-group1 input,
  .contact-form .form-group2 input,
  .contact-form .form-group2 textarea {
    flex: 1 1 100%;
  }

  .contact-form .send-button-form {
    padding: 10px 20px;
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .contact-form {
    padding: 30px 10px;
  }

  .contact-form h2 {
    font-size: 1.2em;
  }

  .contact-form p {
    font-size: 0.9em;
  }

  .contact-form .send-button-form {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}
