.footer {
  background-color: #000;
  color: #fff;
  padding: 20px 40px; /* Increase padding horizontally */
  display: flex;
  justify-content: space-between; /* Keep space between the main blocks */
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping if necessary */
}

.footer p, .footer .contact-info, .footer .social-media {
  margin: 0;
  font-size: 1em;
}

.footer .logo img {
  width: 100px; /* Adjust logo size for balance */
  height: auto;
  margin-bottom: 10px;
}

.footer .contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px; /* Add some left margin for visual separation */
}

.footer .contact-info a {
  display: block; /* Ensure each link is on its own line */
  color: #fff; /* Set color to white */
  font-weight: bold; /* Make phone numbers bold */
  font-size: 1.5em; /* Increase font size */
  text-decoration: none; /* Remove underline */
  margin-bottom: 10px; /* Space between numbers */
}

.footer .contact-info a:hover {
  color: #ccc; /* Change color on hover */
  text-decoration: underline; /* Add underline on hover */
}

.footer .contact-info .faWhatsapp {
  margin-right: 10px; /* Space between icon and number */
}

.footer .social-media {
  font-size: 2.5em; /* Increase the size for visibility */
  color: #ffffff; /* Ensure high contrast and visibility */
  display: flex;
  align-items: center;
}

.footer .social-media a {
  color: inherit;
  margin: 0 10px; /* Space out icons */
}

.footer .social-media a:hover {
  color: #ccc; /* Light grey color on hover */
}
