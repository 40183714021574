.header {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 10px 0; /* Increased padding for a more substantial header */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.header:hover {
  background-color: #f8f8f8;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* Ensured consistent padding */
  max-width: 1200px;
  margin: 0 auto;
}

.logo img {
  width: 70px; /* Increased logo size for better visibility */
  height: auto;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

nav ul li {
  margin: 0 20px; /* Increased margin for better spacing */
  transition: transform 0.3s ease, color 0.3s ease;
}

nav ul li:hover {
  transform: translateY(-3px); /* Reduced translateY for small screens */
}

nav ul li a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
  font-size: 14px; /* Increased font size for better readability */
}

.language-selector select {
  padding: 8px; /* Increased padding for better usability */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px; /* Increased font size for better readability */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    padding: 10px; /* Reduced padding for smaller screens */
  }

  .logo img {
    margin-bottom: 20px; /* Adds space between logo and menu */
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 10px 0; /* Adjusted margin for vertical layout */
  }
}
